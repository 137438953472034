<template>
  <div class="page-cancel-enter-code page-content">
    <KlubbaRichText class="texts">
      <template v-if="userSubscription">
      <p><b>Cancel subscription</b></p>
      <p>
        Are you sure you want to cancel your {{ club.name }} -
        {{ selectedClubSubscription.name }} subscription?
      </p>
      <p><b class="text-danger">Warning this cannot be undone</b></p>
      <p>
        If you would like to subscribe again,
        you will need to follow the subscription process again.
      </p>
      </template>
      <template v-else>
        <p><b>Looks like you don't have any subscription yet</b></p>
        <p>Please choose one on {{ club.name }} products overview page!</p>
      </template>
    </KlubbaRichText>

    <div class="button-wrap">
      <klubba-button
        v-if="userSubscription"
        @click="cancelSubscription"
        buttonText='Yes, cancel my subscription'
        :loading="isLoading"
        theme="danger"
      />
      <klubba-button
        v-else
        @click="goToAddSubscriptionPage"
        buttonText='Choose subscription'
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import swal from 'sweetalert';
import KlubbaButton from '@/components/default/KlubbaButton.vue';
import KlubbaRichText from '../../components/default/KlubbaRichText.vue';
import firebase from '../../config/firebase';

export default {
  components: {
    KlubbaRichText,
    KlubbaButton,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    async cancelSubscription() {
      this.isLoading = true;

      const cancelSubscription = await firebase.functions().httpsCallable('cancelSubscription');
      await cancelSubscription({
        user_id: this.firebaseUser.uid,
        sub_id: this.club.id,
      }).then(() => {
        this.$store.commit('setUserSubscription', null);
        this.$router.push(`/${this.club.id}/subscription-removed`);
      }).catch((error) => {
        console.error(error);
        swal('Error', error.message, 'error');
      });

      this.isLoading = false;
    },
    goToAddSubscriptionPage() {
      this.$router.push(`/${this.club.id}/product-details`);
    },
  },
  computed: {
    ...mapGetters(['selectedClubSubscription', 'club', 'firebaseUser', 'userSubscription']),
  },
};
</script>

<style lang="sass" scoped>
.texts
  @apply px-8 pt-8

.button-wrap
  @apply p-8 mt-auto
</style>
